import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './Components/Sass/main.scss'
import Home from './Components/Pages/Home';
import PrivacyPolicy from './Components/Pages/PrivacyPolicy'
import Qmarco300 from './Components/Pages/Qmarco300';
import Qmarco369 from './Components/Pages/Qmarco369';


function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products/qmarco-300" element={<Qmarco300 />} />
          <Route path="/products/qmarco-369" element={<Qmarco369 />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
